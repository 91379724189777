import React from "react";
import { genrePlayList } from "../../constants";
import "./GenrePlaylist.scss";

const GenrePlaylist = (props) => {
  const { selectedGenre, onSelectGenre } = props;
  return (
    <div className="genre-playlist-container">
      <h1 className="genre-playlist-title">Lofi Player</h1>
      <div className="selected-list-wrapper">
        <select
          value={selectedGenre}
          onChange={(e) => onSelectGenre(e.target.value)}
          className="genre-list"
        >
          {Object.keys(genrePlayList).map((genre, index) => (
            <option key={index} value={genre}>
              {genre}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default React.memo(GenrePlaylist);
