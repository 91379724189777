import React, { useEffect, useState } from "react";
import axios from "axios";
import { random } from "../../utils";
import "./YoutubePlayer.scss";
import icons from "../../icons";

const YoutubePlayer = ({ playlistId }) => {
  const [player, setPlayer] = useState(null);
  const [playerState, setPlayerState] = useState(false);
  const [volume, setVolume] = useState(
    () => parseInt(localStorage.getItem("volume")) || 100
  );

  const songsCount = async (playlistId) => {
    try {
      let allItems = [];
      const apiKey = process.env.REACT_APP_API_KEY_YOUTUBE;
      let pageToken;

      while (pageToken !== null) {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems?key=${apiKey}&playlistId=${playlistId}&enablejsapi=1&maxResults=50${
            pageToken ? `&pageToken=${pageToken}` : ""
          }`
        );

        allItems = allItems.concat(response.data.items);
        pageToken = response.data.nextPageToken || null;
      }

      return allItems.length;
    } catch (error) {
      console.error("Error fetching playlist items:", error);
      return -1; // Handle error
    }
  };

  const loadYouTubeAPI = () => {
    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  };

  const initPlayer = async () => {
    const count = await songsCount(playlistId);
    const index = random(0, count - 1);
    //setCurrentIndexPlayer(index);
    const onVideoReady = (e) => {
      e.target.loadPlaylist({
        list: playlistId,
        listType: "playlist",
        index: index,
        startSeconds: 0,
        suggestedQuality: "small",
      });
    };
    const onPlayerStateChange = (e) => {
      handlePlayerStateChange(e);
    };

    const playerExists = document.querySelector("#player");

    if (!playerExists) {
      const playerWrapper = document.querySelector(".player-wrapper");
      const playerDiv = document.createElement("div");
      playerDiv.id = "player";
      playerWrapper.insertBefore(playerDiv, playerWrapper.firstChild);
    }

    if (
      typeof YT === "undefined" ||
      typeof window.YT.Player === "undefined" ||
      !player
    ) {
      loadYouTubeAPI();
      window.onYouTubeIframeAPIReady = () => {
        setPlayer(
          new window.YT.Player("player", {
            height: "300",
            width: "300",
            playerVars: {
              autoplay: 1,
              control: 0,
              origin: window.location.href,
            },
            events: {
              onReady: onVideoReady,
              onStateChange: onPlayerStateChange,
            },
          })
        );
      };
    } else {
      setPlayer(
        new window.YT.Player("player", {
          height: "300",
          width: "300",
          playerVars: {
            autoplay: 1,
            control: 0,
            origin: window.location.href,
          },
          events: {
            onReady: onVideoReady,
            onStateChange: onPlayerStateChange,
          },
        })
      );
    }
  };

  const reinitPlayer = async () => {
    if (player) {
      player.destroy();
    }
    await initPlayer();
  };

  const handlePlayerStateChange = (event) => {
    if (event.data === 1) {
      setPlayerState(true);
    } else {
      setPlayerState(false);
    }
  };

  const togglePlayer = () => {
    if (playerState) {
      player.pauseVideo();
    } else {
      player.playVideo();
    }
  };

  useEffect(() => {
    reinitPlayer();
  }, [playlistId]);

  const randomPlayer = async () => {
    if (player) {
      const count = await songsCount(playlistId);
      const index = random(0, count - 1);

      // Now, call the loadPlaylist function
      player.loadPlaylist({
        list: playlistId,
        listType: "playlist",
        index: index,
        startSeconds: 0,
        suggestedQuality: "small",
      });
    }
  };

  const handleVolumeChange = (e) => {
    if (player) {
      const newVolume = parseInt(e.target.value);
      player.setVolume(newVolume);
      localStorage.setItem("volume", newVolume);
      setVolume(newVolume);
    }
  };

  return (
    <div className="player-wrapper">
      <div id="player" />
      <div className="control-player">
        <div class="buttons-wrapper left" onClick={() => randomPlayer()}>
          <div class="button-container">
            <div class="button-left button">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-3"></div>
              <div class="dot dot-4"></div>
              <div class="dot dot-5"></div>
            </div>
          </div>
          <div class="button-container">
            <div class="button-left button">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-3"></div>
              <div class="dot dot-4"></div>
              <div class="dot dot-5"></div>
            </div>
          </div>
        </div>
        {playerState ? (
          <img
            src={icons[0]}
            alt=""
            style={{ width: "100px", cursor: "pointer" }}
            onClick={() => togglePlayer()}
          />
        ) : (
          <img
            src={icons[1]}
            alt=""
            style={{ width: "100px", cursor: "pointer" }}
            onClick={() => togglePlayer()}
          />
        )}
        <div class="buttons-wrapper right" onClick={() => randomPlayer()}>
          <div class="button-container">
            <div class="button-right button">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-3"></div>
              <div class="dot dot-4"></div>
              <div class="dot dot-5"></div>
            </div>
          </div>
          <div class="button-container">
            <div class="button-right button">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-3"></div>
              <div class="dot dot-4"></div>
              <div class="dot dot-5"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-vol">
        <input
          id="button-vol-input"
          type="range"
          value={volume}
          min="0"
          max="100"
          onChange={(e) => handleVolumeChange(e)}
        />
      </div>
    </div>
  );
};

export default YoutubePlayer;
